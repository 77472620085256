// extracted by mini-css-extract-plugin
export var root = "PriceItem-module--root--2IWU_";
export var active = "PriceItem-module--active--iwBXh";
export var header = "PriceItem-module--header--2CeA_";
export var planLabel = "PriceItem-module--planLabel--idygr";
export var content = "PriceItem-module--content--3Z-V2";
export var employeesAmount = "PriceItem-module--employeesAmount--X_ODQ";
export var employeesLabel = "PriceItem-module--employeesLabel---bJBZ";
export var employeesCount = "PriceItem-module--employeesCount--PcMLS";
export var rangeContainer = "PriceItem-module--rangeContainer--2EtHl";
export var priceContainer = "PriceItem-module--priceContainer--11tKf";
export var price = "PriceItem-module--price--Xd3Mu";
export var currency = "PriceItem-module--currency--2bfrH";
export var period = "PriceItem-module--period--3Ns_e";
export var infoLabelContainer = "PriceItem-module--infoLabelContainer--HUhjy";
export var infoLabel = "PriceItem-module--infoLabel--cyPxM";
export var footer = "PriceItem-module--footer--28v2g";